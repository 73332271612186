<template>
  <CustomButton type="minimal-light" shape="pill" size="sm" icon-size="sm">
    <template #icon>
      <svg class="h-[1.25rem] w-[1.25rem]" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.42726 2.58789C8.56976 2.75668 8.77943 2.85406 9.00033 2.85406C9.22122 2.85406 9.43089 2.75668 9.57339 2.58789C10.1157 1.94559 11.0264 1.25 12.4225 1.25C14.8304 1.25 16.5837 3.58063 16.5837 5.89531C16.5837 8.30783 15.0895 10.523 13.2775 12.1842C12.3825 13.0048 11.4405 13.6609 10.6335 14.108C10.2299 14.3316 9.86935 14.4977 9.57337 14.606C9.26213 14.7199 9.0752 14.75 9.00033 14.75C8.92545 14.75 8.73852 14.7199 8.42728 14.606C8.1313 14.4977 7.77076 14.3316 7.36716 14.108C6.56015 13.6609 5.61811 13.0048 4.72314 12.1842C2.91119 10.523 1.41699 8.30783 1.41699 5.89531C1.41699 3.58063 3.17021 1.25 5.5781 1.25C6.97422 1.25 7.88497 1.94559 8.42726 2.58789Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </template>
  </CustomButton>
</template>

<script setup>
import CustomButton from "@components/vue-elements/CustomButton.vue";
</script>